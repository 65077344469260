<template>
  <v-dialog :value="value" persistent width="800">
    <v-card color="fill">
      <v-toolbar color="main" class="white--text">
        <v-toolbar-title>Exports</v-toolbar-title>
        <v-spacer />
        <v-btn
          dark
          icon
          class="text-capitalize"
          @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar color="fill" flat>
        <v-row dense align="center">
          <v-col cols="5">
            <v-text-field
              hide-details
              dense
              outlined
              background-color="input"
              label="Filter Exports"
              prepend-inner-icon="mdi-filter-outline"
              v-model="search">
            </v-text-field>
          </v-col>
          <v-spacer />
          <v-btn
            :loading="exporting"
            class="text-capitalize"
            color="primary"
            @click="exportOrders">
            Create Export
          </v-btn>
          <v-btn
            :loading="refreshing"
            class="text-capitalize ml-2"
            @click="getExported(true)">
            Refresh
          </v-btn>
        </v-row>
      </v-toolbar>
      <v-container class="pa-0 ma-0">
        <v-data-table
          :headers="headers"
          :items="exports"
          height="350"
          items-per-page="5000"
          :search="search"
          hide-default-footer
          fixed-header
          dense
          :loading="loading"
          no-data-text="No Exports Available">
          <template v-slot:[`item.download`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn height="36" icon v-on="on" @click="downloadFile(item)">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderGrid from '@/axios/order-grid'
import { userAccess } from '@/mixins/user-access'
import store from '@/store/index'

export default {
  props: {
    value: Boolean,
    ad: Object,
    stores: Array,
    userEmail: String
  },
  mixins: [userAccess],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'file_name',
          class: 'accent white--text',
          sortable: true,
          filterable: true
        },
        {
          text: 'Created On',
          value: 'created_time',
          class: 'accent white--text',
          sortable: true,
          filterable: true
        },
        {
          text: 'Actions',
          value: 'download',
          class: 'accent white--text',
          sortable: true
        }
      ],
      search: '',
      exports: [],
      loading: true,
      exporting: false,
      refreshing: false
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.getExported()
        }
      },
      immediate: true
    }
  },
  methods: {
    async exportOrders() {
      this.exporting = true
      const adId = this.ad.id
      const storeIds = this.stores.map(store => store.id)
      try {
        const payload = {
          ad_id: adId,
          store_ids: storeIds,
          email_address: this.userEmail
        }
        const response = await OrderGrid.exportStoresOrder(payload)
        if (response.data) {
          store.dispatch('showSnackbar', {
            status: 'success',
            message: 'Export Submitted'
          })
        }
      } catch (error) {
        store.dispatch('showSnackbar', {
          status: 'error',
          message: 'Error: Request Failed'
        })
      } finally {
        this.exporting = false
      }
    },
    async getExported(refresh) {
      if (refresh) this.refreshing = true
      try {
        const response = await OrderGrid.getExported(this.userEmail)
        if (response.data.length > 0) {
          this.exports = response.data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.refreshing = false
        this.loading = false
      }
    },
    downloadFile(file) {
      var win = window.open(file.url)
      win.focus()
    }
  }
}
</script>
